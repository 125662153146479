<template>
  <div>
    <div class="color-select">
      <div class="color-select-header">
        <span class="color-select-header-title">颜色选择</span>
        <a href="javascript:;" @click="confirm" class="color-select-header-confirm">确定</a>
      </div>
      <div class="color-select-content">
        <div class="color-select-tab">
          <a href="javascript:;" :class="{'active': activeTab === '1'}" @click="handleTabClick('1')">常用颜色</a>
          <a href="javascript:;" :class="{'active': activeTab === '2'}" @click="handleTabClick('2')">自定义颜色</a>
        </div>
        <div class="color-select-panel" v-show="activeTab === '1'">
          <div class="color-select-block" v-for="(color, index) in commonColors" :key="index">
            <div :class="['color-select-item', {'active': selectedColor === color}]" :style="{background: color}" @click="handleColorSelect(color)"></div>
          </div>
        </div>
        <div class="color-select-panel" v-show="activeTab === '2'">
          <div class="color-select-block">
            <input type="color" v-model="customColor" @change="handleCustomColorSelect"/>
          </div>
        </div>
      </div>
    </div>
    <div class="color-select-mask" v-show="visible" @click="cancel"></div>
  </div>
</template>
<script>
export default {
  name: 'ColorSelect',
  props: {
    value: {
      type: String,
      default: '',
    },
    commonColors: {
      type: Array,
      default: () => [
        '#FFFFFF',
        '#000000',
        '#D0021B',
        '#F5A623',
        '#F8E71C',
        '#8B572A',
        '#7ED321',
        '#417505',
        '#BD10E0',
        '#9013FE',
        '#4A90E2',
        '#50E3C2',
        '#B8E986',
        '#000000',
      ],
    },
  },
  data() {
    return {
      visible: false,
      activeTab: '1',
      selectedColor: '',
      customColor: '#FFFFFF',
    }
  },
  mounted() {
    this.selectedColor = this.value
  },
  methods: {
    initcolor(value) {
      this.selectedColor=value
    },
    handleTabClick(tab) {
      this.activeTab = tab
    },
    handleColorSelect(color) {
      this.selectedColor = color
    },
    handleCustomColorSelect(event) {
      this.customColor = event.target.value
    },
    confirm() {
      this.$emit('input', this.selectedColor)
      this.close()
    },
    cancel() {
      this.close()
    },
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
  },
  watch: {
    value: function(newVal) {
      this.selectedColor = newVal
    },
  },
}
</script>
<style scoped>
.color-select {
  background: #F7F7F7;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  border-top: 1px solid #E6E6E6;
  padding: 10px;
}
.color-select-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}
.color-select-header-title {
  flex: 1;
}
.color-select-header-confirm {
  padding: 6px;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  color: #666;
  font-size: 12px;
  text-align: center;
}
.color-select-content {
  display: flex;
  flex-direction: column;
  height: 240px;
}
.color-select-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  padding: 6px;
  margin-bottom: 10px;
}
.color-select-tab a {
  color: #333333;
  font-size: 12px;
  text-align: center;
  line-height: 24px;
}
.color-select-tab a.active {
  color: #FFFFFF;
  background: #1890ff;
  border-radius: 4px;
}
.color-select-panel {
  flex: 1;
  overflow: auto;
}
.color-select-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
}
.color-select-item {
  width: 20%;
  height: 30px;
  cursor: pointer;
}
.color-select-item.active {
  border: 2px solid #1890ff;
}
.color-select-mask {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>